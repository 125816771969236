<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>日期</th>
            </tr>
            <tr>
              <th>體重</th>
            </tr>
            <tr>
              <th>體脂</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in weights" :key="row.date+row.time">
              <td>{{ row.date }}</td>
              <td>{{ row.weight }}</td>
              <td>{{ row.fat }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Record',
  data: ()=>({
    weights: []
  }),

  created(){
    axios.get('https://weight-tracker.blackcityhenry.workers.dev')
    .then(res=>{
      this.weights = res.data
    })
  }
}
</script>

<style>
.record-form{
  width: 800px;
  max-width: 100%;
  margin: auto;
}
</style>
