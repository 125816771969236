<template>
  <v-row>
    <div class="record-form d-flex flex-column justify-center">
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="date" outlined label="日期" readonly></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="time" outlined label="時間" readonly></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="$store.state.weight" outlined label="體重"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="$store.state.fat" outlined label="體脂" subfix="%"></v-text-field>
        </v-col>
      </v-row>
      <v-btn class="primary" disabled="submitable">
        <v-icon>
          mdi-clipboard-check-outline
        </v-icon>
        提交
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Record',
  data: ()=>({

  }),
  computed: {
    date(){
      return new Date().toDateString();
    },
    time(){
      return new Date().toTimeString();
    },
    submitable(){
      let temp = false;
      temp = this.$store.state.weight === '' && this.$store.state.fat === ''
      return temp;
    }
  },

}
</script>

<style>
.record-form{
  width: 800px;
  max-width: 100%;
  margin: auto;
}
</style>
