import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Record from '../views/Record.vue'
import Chart from '../views/Chart.vue'
import List from '../views/List.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/record',
    name: 'record',
    component: Record
  },
  {
    path: '/chart',
    name: 'chart',
    component: Chart
  },
  {
    path: '/list',
    name: 'list',
    component: List
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Login,
    props: { isRegister: true }
  }
]

const router = new VueRouter({
  routes
})

export default router
