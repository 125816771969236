<template>
  <v-row></v-row>
</template>

<script>
export default {
  name: 'Chart',
  data: ()=>({

  })
}
</script>

<style>

</style>
