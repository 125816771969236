<template>
  <v-app>
    <mod-header></mod-header>
    <v-main class="secondary">
      <v-container fill-height fluid>
        <transition name="slide" :duration="300" mode="out-in">
          <router-view :key="$route.path"></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'App',

  components: {
    'mod-header': Header
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.slide-enter-to, .slide-leave{
  opacity: 1;
  transition: 0.5s;
}

.slide-leave-to{
  opacity: 0;
  transform: translateX(-20%);
  transition: 0.5s;
}

.slide-enter{
  opacity: 0;
  transform: translateX(20%);
  transition: 0.5s;
}
</style>
