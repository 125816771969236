<template>
  <v-row class="justify-center">
    <v-col cols="4">
      <v-card class="pa-4">
        <v-card-title v-if="isRegister">註冊</v-card-title>
        <v-card-title v-else>登入</v-card-title>
        <v-text-field v-model="username" outlined label="用戶名"></v-text-field>
        <password-field paramLabel="密碼" v-model="password" :paramError="error"></password-field>
        <password-field v-if="isRegister" paramLabel="確認密碼" v-model="confirmPassword" :paramError="error" @blur="checkIdentical(confirmPassword)"></password-field>
        <v-card-text v-if="isRegister">
          已經有 Account ? <router-link to="/login">登入</router-link>
        </v-card-text>
        <v-card-text v-else>
          未有 Account ? <router-link to="/register">註冊</router-link>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" v-if="isRegister">
            <v-icon>
              mdi-account-plus
            </v-icon>
            註冊
          </v-btn>
          <v-btn class="primary" v-else>
            <v-icon>
              mdi-account-arrow-right
            </v-icon>
            登入
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PasswordField from '@/components/PasswordField'
import axios from 'axios'

export default {
  name: 'Login',
  props: ['isRegister'],
  data: ()=>({
    username: '',
    password: '',
    confirmPassword: '',
    error: ''
  }),
  components: {
    'password-field': PasswordField
  },
  methods: {
    checkIdentical(val){
      if ( this.password !== val ){
        this.error = '密碼不相同'
      } else {
        this.error = ''
      }
    },
    register(){
      if ( this.error === '' ){
        let obj = {
          username: this.username,
          // hash: this.password.
        }
        axios.post('/register', obj)
      } else {
        alert('確認多次密碼！')
      }
    },
    login(){

    }
  },

}
</script>

<style>
.login-form{
  width: 400px;
  max-width: 100%;
  margin: auto;
}
</style>
