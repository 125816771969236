<template>
  <v-text-field
  outlined
  :label="paramLabel"
  :type="type"
  :append-icon="passwordIcon"
  :value="paramModel"
  :error="paramError.length !== 0"
  :hint="paramError"
  @click:append="toggleShow"
  @input="$emit('input', $event)"
  @blur="$emit('blur', $event)"
  persistent-hint
  ></v-text-field>
</template>

<script>
  export default {
    name: 'PasswordField',
    props: ['paramLabel', 'paramModel', 'paramError'],
    data: () => ({
      showPassword: false,
      passwordIcon: 'mdi-eye-off',
      type: 'password'
    }),
    methods: {
      toggleShow(){
        this.showPassword = !this.showPassword;
      }
    },
    watch: {
      showPassword(boolean) {
        if ( boolean === true ){
          this.passwordIcon = 'mdi-eye',
          this.type = 'text'
        } else {
          this.passwordIcon = 'mdi-eye-off',
          this.type = 'password'
        }
      }
    }
  }
</script>

<style>

</style>
